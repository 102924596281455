* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

hr {
  border: 1px solid #d6deea;
}

iframe {
  width: 100%;
  height: 10rem;
  background: #d6deea;
  border: 0;
}

svg {
  display: block;
}
